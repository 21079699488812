@import '../../../scss/imports';

.transaction__list {
  --corner-radius: .25rem;

  display: grid;
  grid-row-gap: .375rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0;
  padding: 0 1.5rem 2.25rem;
}

.transaction__detail,
.transaction__term {
  align-items: center;
  background-color: var(--white);
  display: grid;
  font-size: .875rem;
  padding: .75rem;

  &[hidden] {
    display: none;
  }
}

.transaction__detail {
  border-radius: 0 var(--corner-radius) var(--corner-radius) 0;
  font-family: var(--font-demi);
  margin: 0;
  padding-left: 0;
  text-transform: capitalize;
}

.transaction__term {
  border-radius: var(--corner-radius) 0 0 var(--corner-radius);
  grid-column-gap: 1rem;
  grid-template-columns: auto 1fr;

  &[data-icon='check_circle_outline']::before {
    color: var(--success);
  }

  &[data-icon='error_outline']::before {
    color: var(--warning);
  }

  &[data-icon='highlight_off']::before {
    color: var(--danger);
  }
}

@media (min-width: $sm) {
  .transaction__detail {
    svg,
    i {
      display: none;
    }
  }
}

@media (max-width: $sm) {
  .transaction__list {
    background: #e6e6e6;
    border-radius: .75rem;
    box-shadow: 0 .375rem 2rem 0 rgba(130, 141, 168, .2);
    grid-row-gap: .0625rem;
    margin: 1.375rem 1rem 1.125rem;
    padding: 0;

    dd,
    dt {
      border-radius: 0;

      &:last-of-type {
        padding-bottom: 1.5rem;
      }
    }

    dt:first-of-type {
      border-top-left-radius: .75rem;
    }

    dd:first-of-type {
      border-top-right-radius: .75rem;
    }

    dt:last-of-type {
      border-bottom-left-radius: .75rem;
    }

    dd:last-of-type {
      border-bottom-right-radius: .75rem;
    }

    dt {
      svg,
      i {
        display: none;
      }
    }

    .transaction__detail {
      display: flex;

      svg,
      i {
        margin-right: .375rem;
      }

      .icon-success {
        color: rgba(144, 220, 19, 1);
      }

      .icon-failed {
        color: #ef5350;
      }

      .icon-default {
        color: rgb(253, 216, 53);
      }
    }

    .cashier__detail {
      text-transform: none;
    }
  }
}

#single-transaction-view-modal {
  .error-message {
    color: $priority-color;
    margin-top: 1rem;
    padding: 0 1.5rem;
    text-align: center;
  }
}
