@import '../../scss/imports';

.no-cashier-revenue {
  margin-top: 4rem;
  text-align: center;
}

.cashier-event-list {
  display: grid;
  font-family: var(--font-demi);
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 1.25rem;
}

.cashier-event-button {
  background: var(--white);
  border-radius: .25rem;
  font-size: 1.125rem;
  padding: 1rem;
  text-align: left;
  width: 100%;
}

@media (max-width: $sm) {
  .cashier-event-button {
    box-shadow: 0 .375rem 1.625rem 0 rgba(130, 141, 168, .2);
  }
  
  .modal-subhead {
    display: none;
  }
}
