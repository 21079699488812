@import '../../scss/imports';

.mobile-list-container {
  font-size: 1.125rem;
  position: inherit;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    width: calc(100vw - 3.5rem);
  }

  .mobile-list-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1rem 1.25rem 1rem 1.5rem;

    ul {
      font-weight: 700;
      height: 1.25rem;
      margin: .5rem .75rem .75rem .5rem;
      width: calc(100% - 1.25rem);

      li {
        display: flex;
        width: 50%;
      }

      button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      .sort-secondary-column {
        justify-content: flex-end;
      }

      .header-text {
        max-width: calc(50vw - 4.625rem);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        min-width: .625rem;

        &[direction='asc'] {
          opacity: 1;
        }

        &[direction='desc'] {
          opacity: 1;
        }
      }
    }

    .rmd-icon-rotator--animate {
      transition: none;
    }

    .rmd-button--text {
      background: rgba(97, 97, 97, .1);
      border-radius: .75rem;
      min-height: 1.5rem;
      min-width: 1.5rem;
    }

    #mobile-action-sheet {
      min-width: 1.5rem;
      padding: 0 .25rem;

      i {
        margin-left: 0;
      }
    }
  }

  .list-item {
    background: $white;
    border-radius: .75rem;
    box-shadow: 0 .375rem 2rem rgba(130, 141, 168, .2);
    margin: 1rem;
    padding: .6563rem .25rem .4375rem .75rem;

    svg,
    i {
      margin-right: .5rem;
    }

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    li:nth-of-type(1) {
      padding-right: .75rem;
      width: calc(60% - .875rem);
    }

    li:nth-of-type(2) {
      margin-right: .5rem;
      text-align: right;
      width: calc(40% - 1.5rem);

      .table__button--body {
        text-align: right;
      }
    }

    li:nth-of-type(3) {
      width: 1.5rem;

      i {
        color: rgba(181, 181, 181, 1);
      }
    }
  }

  .mobile-list-body {
    .list-item {
      display: flex;
      justify-content: space-between;
    }

    .column-main {
      font-weight: 700;
    }

    .highlight-cell {
      background: rgba(255, 87, 34, .1);
      border-radius: .1875rem;
      color: #ff5722;
      font-weight: bold;
      line-height: 1.625rem;
      margin-left: auto;
      max-width: 2.625rem;
      padding-right: .1875rem;
    }

    [data-status-indicator='danger'] {
      background-color: var(--danger-light);
    }

    [data-status-indicator='warning'] {
      background-color: var(--warning-light);
    }

    [data-status-indicator='info'] {
      background-color: var(--info-light);
    }
  }

  .table__button--body {
    height: 1.25rem;
  }
}

.mobile-transactions-modal {
  .mobile-list-header {
    margin-left: 3.25rem;
  }

  .list-item {
    li:nth-of-type(1) {
      width: calc(35% - .875rem);
    }

    li:nth-of-type(2) {
      width: calc(65% - 1.5rem);
    }
  }
}

.mobile-vip-modal {
  .list-item {
    padding: .75rem .25rem .75rem .75rem;
  }
}

#mobile-action-sheet-menu-sheet {
  --rmd-sheet-transform-offscreen: translate3d(0, 100%, 0);
  border-radius: 1rem;
  margin: 11.4375rem .75rem 0;
  min-height: 6rem;
  width: calc(100% - 1.5rem);
  z-index: 205;

  .mobile-menu-title {
    font-weight: 700;
    line-height: 1.5313rem;
    margin-top: 1rem;
    overflow: visible;
    text-overflow: initial;
    white-space: initial;
  }

  ul {
    padding-bottom: 0;
  }

  li {
    min-height: 3rem;
    padding: 0 1rem;
  }

  &.rmd-sheet--raised {
    box-shadow: none;
  }

  .rmd-toggle__icon--overlay {
    --rmd-theme-secondary: #2196f3;
    color: rgba(218, 218, 218, 1);
  }
}

#mobile-action-sheet-menu-sheet-overlay {
  opacity: .4;
  z-index: 204;
}

.mobile-sheet-footer {
  height: .5rem;
}

@include landscape() {
  @include max-screen($md) {
    #mobile-action-sheet-menu-sheet {
      margin: 4.5rem .75rem;
      max-height: 76%;
    }
  }
}
