@import '../../scss/imports';

$z-modal: 200;
$backdrop: rgba(0, 0, 0, .4);

.modal {
  --button-diameter: 2rem;
  --button-line-length: 1.25rem;
  --button-line-width: .125rem;
  --content-background: var(--gray-100);
  --padding-top: 7.25rem;
  --padding-x: #{$wrapper-padding};

  bottom: 0;
  color: $text-color;
  display: grid;
  justify-items: center;
  left: 0;
  padding: var(--padding-top) var(--padding-x) 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-modal + 1;

  @include max-screen($sm) {
    --padding-top: 4.5rem;
    --padding-x: #{$wrapper-padding-sm};
  }

  @include landscape() {
    --padding-top: .75rem;
    --padding-x: .75rem;

    @include max-screen($md) {
      .modal-subhead {
        display: none;
      }
    }
  }

  .modal-body {
    align-content: start;
    display: grid;
    overflow-y: auto;
    position: relative;
  }

  .modal-content {
    background-color: var(--content-background);
    border-radius: .75rem .75rem 0 0;
    box-shadow: 0 .375rem 2rem rgba(130, 141, 168, .2);
    display: grid;
    grid-template-rows: auto 1fr;
    height: calc(100vh - var(--padding-top));
    max-width: 55rem;
    padding: var(--padding-x) 0 0;
    width: 100%;
  }

  .modal-back-button {
    align-items: center;
    display: flex;
    grid-column: 1;
    grid-row: 1;
    justify-content: center;
    padding: .125rem;

    svg {
      max-height: 1rem;
      max-width: 1rem;
      width: 100%;
    }
  }

  .modal-close-button {
    grid-column: 3;
    grid-row: 1;
    position: relative;

    &::after,
    &::before {
      background-color: $text-color;
      content: '';
      height: var(--button-line-width);
      left: calc(50% - .625rem);
      position: absolute;
      top: calc(50% - 1px);
      width: var(--button-line-length);
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  .modal-header {
    display: grid;
    gap: 1rem;
    grid-template-columns: var(--button-diameter) 1fr var(--button-diameter);
    padding: 0 var(--padding-x) var(--padding-x);
    text-align: center;

    @include max-screen($sm) {
      padding-bottom: .375rem;
    }

    button {
      border-radius: 50%;
      height: var(--button-diameter);
      transition: background-color .1s ease-in-out;
      width: var(--button-diameter);

      &:active,
      &:focus,
      &:hover {
        background: rgba(0, 0, 0, .08);
      }
    }
  }

  .modal-headline {
    font-family: var(--font-demi);
    font-size: 2rem;
    grid-column: 2;
    grid-row: 1;
    margin: 0;

    @include max-screen($sm) {
      font-size: 1.5rem;
    }
  }

  .modal-subhead {
    font-size: 1rem;
    grid-column: 1 / -1;
    grid-row: 2;
    margin: 0;
    padding: 0 6rem;

    @include max-screen($sm) {
      padding: 0;
    }
  }

  &.contained {
    bottom: auto;
    height: auto;
    left: 50%;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);

    .modal-content {
      border-radius: .75rem;
      height: auto;
    }
  }
}

.overlay + .modal.tall + .overlay {
  background-color: transparent;
}

.modal-form {
  display: grid;
  grid-row-gap: 3.25rem;
  overflow-y: auto;
  padding: 3.25rem 4.5rem;
}

.modal-form-note {
  color: var(--gray-600);
  font-size: .75rem;
}

.modal-form-review {
  display: grid;
  padding: 3rem;
}

.modal-form-review-buttons {
  display: grid;
  gap: 1rem;
  justify-self: center;
}

.modal-form-review-error {
  margin: 0 0 .25rem;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }
}

.modal-form-review-body,
.modal-form-review-header {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: auto 1fr;
}

.modal-form-review-body {
  padding: 1.5rem 0 3rem;
}

.modal-form-review-details {
  font-family: var(--font-demi);
  text-align: end;

  &[data-status='warning'] {
    color: var(--red);
  }
}

.modal-form-review-header {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 0 0 1.5rem;
}

.modal-form-review-list {
  font-size: 1.125rem;
  margin: 0;
}

.modal-form-submit-button,
.modal-success-button {
  justify-self: center;
}

.modal-body-message {
  padding: 3.25rem 4.5rem;

  p {
    line-height: 1.5;
    margin: 0;
  }
}

.modal-body-button-list {
  display: grid;
  grid-gap: 1.25rem;
  justify-content: center;
  list-style: none;
  padding: 1.5rem 0;

  button {
    width: 100%;
  }
}

.overlay {
  background-color: $backdrop;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-modal;
}

$transition-timing: cubic-bezier(.42, 0, .58, 1);
$fade-delay: 300ms $transition-timing;
$scale-delay: 300ms $transition-timing;
$slide-delay: 300ms $transition-timing;

.modal-fade-enter {
  opacity: 0;
}

.modal-fade-enter-active {
  opacity: 1;
  transition: opacity $fade-delay;
}

.modal-fade-exit {
  opacity: 1;
}

.modal-fade-exit-active {
  opacity: 0;
  transition: opacity $fade-delay;
}

.modal.contained.scale-enter {
  transform: scale(0) translate(-50%, -50%);
  transform-origin: 0 0;
  transform-style: preserve-3d;
}

.modal.contained.scale-enter-active {
  transform: scale(1) translate(-50%, -50%);
  transform-style: preserve-3d;
  transition: transform $scale-delay;
}

.modal.contained.scale-exit {
  transform: scale(1) translate(-50%, -50%);
  transform-origin: 0 0;
  transform-style: preserve-3d;
}

.modal.contained.scale-exit-active {
  transform: scale(0) translate(-50%, -50%);
  transform-style: preserve-3d;
  transition: transform $scale-delay;
}

.slide-enter {
  top: 100%;
}

.slide-enter-active {
  top: 0;
  transition: top $slide-delay;
}

.slide-exit {
  top: 0;
}

.slide-exit-active {
  top: 100%;
  transition: top $slide-delay;
}

@include max-screen($sm) {
  .modal-form {
    background: $white;
    border-radius: .75rem;
    box-shadow: 0 .375rem 1.625rem 0 rgba(130, 141, 168, .2);
    grid-row-gap: .75rem;
    margin: .75rem;
    overflow-y: initial;
    padding: .75rem 0;
  }

  .text-input-fieldset {
    .text-input {
      background: rgba(249, 249, 249, 1);
      border-bottom: 0;
      border-radius: .25rem;
      font-size: .875rem;
      height: 2.75rem;
      margin-bottom: .75rem;
      padding: .5rem;
      text-align: right;
      width: 40%;
    }

    label {
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      padding: 0 .75rem;

      [readonly] {
        background-color: transparent;
        color: rgba(182, 182, 182, 1);
      }

      .text-input-label-text {
        font-size: .875rem;
        margin-bottom: .75rem;
        width: 45%;
      }

      &[for='comments'] {
        align-items: flex-start;
        flex-direction: column;
        padding-right: .75rem;

        .text-input-label-text {
          margin-bottom: .5rem;
        }

        input {
          padding: .5rem;
          text-align: left;
          width: 100%;
        }
      }
    }
  }

  .modal-form-review {
    border-radius: .25rem;
    box-shadow: 0 .375rem 1.625rem 0 rgba(130, 141, 168, .2);
    margin: .75rem;
    padding: .75rem 0;

    .modal-form-review-header {
      font-size: .875rem;
      margin-bottom: .75rem;
      padding: .75rem;
    }

    .modal-form-review-body {
      font-size: .875rem;
      margin-bottom: .75rem;
      padding: .75rem;
    }
  }

  .modal-form-note {
    display: none;
  }
}

#mobile-list-options {
  padding: 8rem .75rem;

  .modal-content {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .75rem;
    box-shadow: 0 0 2.5rem 1.25rem rgba(0, 0, 0, .1);
    height: 19.25rem;
  }

  .rmd-list-item {
    min-height: 2.625rem;
    padding: 0 1rem;
  }
}

#expanded-lots-view-modal {
  @media (max-width: $sm) {
    .search-input {
      display: none;
    }
  }
}
