.dropdown-button-list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .25rem;
  box-shadow: 0 .125rem 8px rgba(0, 0, 0, .2);
  list-style: none;
  margin: 0;
  min-width: 8rem;
  padding: 0;
  position: absolute;
  right: 50%;
  top: 0;
  transform: scale(0);
  transform-origin: top right;
  transition: transform .15s ease-in-out;
  z-index: 1;

  &.visible,
  &:focus-within {
    transform: scale(1);
  }
}

.dropdown-button-list-button {
  align-items: center;
  display: flex;
  font-size: .875rem;
  justify-content: space-between;
  padding: .5rem;
  width: 100%;

  &:focus,
  &:hover {
    background: #ededed;
  }
}

.dropdown-button-list-icon {
  padding: 0 .25rem;

  svg {
    --max-length: .75rem;

    fill: #727272;
    max-height: var(--max-length);
    max-width: var(--max-length);
    width: 100%;
  }
}

.dropdown-button-list-text {
  grid-column: 2;
}
