.segmentation-card-container {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: 1fr;
  padding: 1rem;

  @media (min-width: $md) {
    padding: 1.5rem;
  }
}

.segmentation-form {
  display: none;

  @media (min-width: $sm) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 0 1.5rem;
    width: auto;
  }
}

.segmentation-form-label {
  text-align: center;
}

.segmentation-controls {
  align-items: center;
  display: grid;
  font-size: 1.125rem;
  grid-gap: .25rem;
  grid-template-columns: repeat(3, fit-content(100%));
  padding-left: 1.125rem;
}

.segmentation-card {
  background: #f6f6f6;
  box-shadow: 0 .375rem 2rem 0 rgba(130, 141, 168, .2);
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 1fr;
  padding: 0;

  @media (min-width: $md) {
    border: .25rem solid #f6f6f6;
    box-shadow: none;
  }

  .segmentation-card-section {
    background-color: #fff;
    padding: 1rem;

    &:nth-child(2) {
      border-top-left-radius: .75rem;
      border-top-right-radius: .75rem;
    }

    &:last-child {
      border-bottom-left-radius: .75rem;
      border-bottom-right-radius: .75rem;
    }

    @media (min-width: $sm) {
      padding: 1.5rem;

      &:nth-child(2) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:first-child {
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
      }
    }
  }

  .segmentation-card-separator {
    border-bottom: .125rem solid #f6f6f6;
    display: block;
    margin: 1.5rem 0;
  }

  .segmentation-card-header {
    display: none;

    @media (min-width: $sm) {
      align-items: center;
      display: grid;
      grid-gap: .75rem;
      grid-template-rows: repeat(1, auto);
    }
  }

  .segmentation-card-header-name {
    color: $text-color;
    font-family: $font-demi;
    font-size: 1.375rem;
  }

  .segmentation-card-header-content {
    font-family: $font-regular;
    font-size: 1.125rem;
  }

  .segmentation-card-header-content.event-names {
    @media (min-width: $sm) {
      display: none;
    }
  }

  .segmentation-details {
    dl {
      display: grid;
      font-size: 1.125rem;
      grid-gap: .75rem;
      grid-template-columns: auto auto;
      margin: 0;

      @media (min-width: $sm) {
        .location-details,
        .event-details {
          display: none;
        }
      }

      @media (max-width: $sm) {
        grid-gap: 2px 0;
      }
    }

    dt {
      color: $text-color;
      display: grid;
      font-family: $font-regular;
      grid-gap: .25rem;
      grid-template-columns: auto auto;
      justify-content: left;

      @media (max-width: $sm) {
        dt {
          grid-gap: 2px;
        }
      }
    }

    dd {
      font-family: $font-demi;
      text-align: right;
    }

    .warning-icon {
      cursor: pointer;
      transform: scale(1);
      transition: transform .25s ease-in-out;

      &:hover {
        transform: scale(1.1);
        transition: transform .25s ease-in-out;
      }
    }

    @media (max-width: $sm) {
      padding: 0;

      dd,
      dt {
        background: $white;
        border-bottom: 1px solid rgba(230, 230, 230, 1);
        margin: 0;
        padding: .75rem;
        width: 100%;

        &.event-time-details {
          border-bottom: 0;
          margin-bottom: .75rem;
        }
      }
    }
  }

  .warning-icon {
    color: $warning-color;
    display: none;
  }

  &.warning-status {
    border: .25rem solid rgba($warning-color, .4);

    .warning-icon {
      display: block;
    }

    .warning-value {
      color: $warning-color;
    }
  }
}

.segmentation-form-dropdown {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  .dropdown-radio-checked i {
    color: $primary-color;
  }
}
